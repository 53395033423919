.fade-in-out {
    opacity: 0;
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    transition: all .3s ease-out;
}

    .fade-in-out.toggled {
        opacity: 1;
    }
