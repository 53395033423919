#PINform input:focus,
#PINform select:focus,
#PINform textarea:focus,
#PINform button:focus {
    outline: none;
}

.pinpad-body {
    background-color: #fc0;
}

    .pinpad-body .spinner {
        position: relative;
        bottom: 5em;
    }

        .pinpad-body .spinner > div {
            background-color: #fff;
        }

    .pinpad-body .post-logo {
        font-size: 1.25em;
    }

    .pinpad-body .pinpad {
        text-align: center;        
    }

.set-pin-text {
    text-align: left;
}

.color-white {
    color: white !important;
}

.set-pin-text div {
    position: relative;
    left: 0.5em;
    text-align: left;
}



.pin-dots {
    border-top: 0.15em solid #fff;
    padding-top: 1.75em;
    margin: 0 0em 1em 0em;
}

.PINcontainer {
    position: absolute;
    bottom: 1em;
    left: calc(50% - 160px);
    display: inline-block;
    max-width: 320px;
    text-align: center;
}

.circle {
    display: inline-block;
    position: relative;
    border-radius: 50%;
    width: 1.1em;
    height: 1.1em;
    background-color: #ffcc00;
    -webkit-box-shadow: inset 0 0 2px 2px rgba(0,0,0,0.2);
    -moz-box-shadow: inset 0 0 2px 2px rgba(0,0,0,0.2);
    box-shadow: inset 0 0 2px 2px rgba(0,0,0,0.1);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

    .circle.toggled {
        background: #fff;
        -webkit-box-shadow: inset 0 0 0 0 rgba(0,0,0,0.0);
        -moz-box-shadow: inset 0 0 0 0 rgba(0,0,0,0.0);
        box-shadow: inset 0 0 0 0 rgba(0,0,0,0.0);
    }

.PINbutton {
    background: #dfb200;
    color: #ffffff;
    border: none;
    outline: none;
    -webkit-box-shadow: 0 0 2px 0 rgba(0,0,0,0.1);
    -moz-box-shadow: 0 0 2px 0 rgba(0,0,0,0.1);
    box-shadow: 0 0 3px -1px rgba(63,47,26,1);
    border-radius: 50%;
    font-size: 1.5em;
    text-align: center;
    width: 55px;
    height: 55px;
    margin: 7px 20px;
    padding: 0;
}

.PINspans {
    display: inline-block;
    width: 60px;
}

    .PINspans.left {
        position: relative;
        right: 1.25em;
        bottom: 0.25em;
        color: #3F2F1A;
    }

    .PINspans.right {
        color: white;
        position: relative;
        left: 1.25em;
        top: 0.25em;
    }

.PINbutton:active {
    background: #3F2F1A;
    border-color: #fff;
    color: #fff;
}

@media (max-height: 600px) {
    .PINbutton {
        width: 45px;
        height: 45px;
        font-size: 1.25em;
    }

    .PINspans.left {
        font-size: 0.75em;
        font-weight: bold;
    }

    .PINspans.right {
        left: 0.75em;
    }
}

@media (max-height: 560px) and (orientation:landscape) {
    .PINcontainer {
        left: 0;
        max-width: 100%;
        padding-left: 0;
    }

    .pinpad-body .pinpad {
        max-width: 50%;
        display: inline-block;
    }

    .pin-dots {
        border-top: 0 solid #fc0;
        border-bottom: 0.15em solid #fff;
        margin-bottom: 1rem;
        padding-bottom: 0.5em;
    }

    .set-pin-text {
        display: inline-block;
        vertical-align: top;
        max-width: 50%;
        position: relative;
        padding-left: 1.5em;
        top: 1em;
        margin-right: 1em;
    }

    .PINbutton {
        width: 30px;
        height: 30px;
        font-size: 1em;
    }

    .PINspans.left {
        font-size: 0.75em;
        font-weight: bold;
        right: 0.5em;
    }

    .PINspans.right {
        font-size: 0.75em;
        left: 0.5em;
    }
}
