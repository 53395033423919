.electron .sign-in {
    padding-top: 2.5em;
}

    .electron .sign-in .tb .tb-row .tb-clm .col-content {
        box-shadow: 0 0.25em 0.25em 0.1em rgba(0,0,0,0.5)
    }

.electron .container-fluid {
    padding-top: 2.5em;
}

.electron .menu-bar {
    margin-top: 2.5em;
    background-color: transparent;
}

    .electron .menu-bar .navbar {
        display: none;
    }

    .electron .menu-bar .dx-scrollable-content {
        background-color: #fff;
    }

.electron .top-bar {
    background-color: #78658E;
}

    .electron .top-bar .view-mode span {
        background-color: #78658E !important;
    }

    .electron .top-bar .right-menu li a, .electron .top-bar .right-menu li span {
        background-color: #78658E !important;
    }

    .electron .top-bar .dx-switch-on-value .dx-switch-container:before {
        background-color: rgba(82, 49, 120, 0.5) !important;
    }

    .electron .top-bar .dx-switch-on-value .dx-switch-handle:before {
        background-color: #523178 !important;
    }

.electron .info-bar {
    margin-top: 2.5em;
}

    .electron .info-bar .container-fluid {
        padding-top: 1em;
    }

.electron .dx-fa-button.dx-fa-button-main .dx-overlay-content {
    background-color: rgba(82, 49, 120, 0.5);
}

.electron .dx-fa.electron -button.dx-fa-button-main .dx-overlay-content:hover {
    background-color: #523178;
}

.electron .public {
    padding-top: 2.5em;
}

.electron .electron-toolbar {
    background-color: #523178;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 2.5em;
    -webkit-app-region: drag;
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    transition: all .3s ease-out;
}

    .electron .electron-toolbar:hover {
        -webkit-box-shadow: rgba(0,0,0,0.5) 0 0 0.125em;
        -moz-box-shadow: rgba(0,0,0,0.5) 0 0 0.125em;
        box-shadow: rgba(0,0,0,0.5) 0 0.1em 0.125em;
    }

    .electron .electron-toolbar .app-title {
        color: white;
        text-align: center;
        line-height: 2.5em;
        vertical-align: middle;
        -webkit-user-select: none;
    }

    .electron .electron-toolbar .buttons {
        color: white;
    }

        .electron .electron-toolbar .buttons .button {
            -webkit-app-region: no-drag;
        }

        .electron .electron-toolbar .buttons .button {
            display: inline-block;
            width: 2.5em;
            height: 2.5em;
            line-height: 2.5em;
            vertical-align: middle;
            text-align: center;
            cursor: pointer;
            -webkit-transition: all .3s ease-out;
            -moz-transition: all .3s ease-out;
            -o-transition: all .3s ease-out;
            transition: all .3s ease-out;
        }

        .electron .electron-toolbar .buttons button:hover {
            background-color: rgba(255, 255, 255, 0.25);
        }

        .electron .electron-toolbar .buttons .navigation {
            text-align: left;
        }

        .electron .electron-toolbar .buttons.window {
            text-align: right;
        }

            .electron .electron-toolbar .buttons.window div.minimize svg {
                position: relative;
                bottom: 0.35em;
            }

@media (max-width: 991px) {
    .electron .page-container {
        min-height: calc(100% - 4.35em);
    }
}

@media (max-width: 800px) {
    .electron .page-container {
        min-height: calc(100% - 4.9em);
    }
}

@media (max-width: 575px) {
    .electron .page-container {
        min-height: calc(100% - 5.8em);
    }
}

@media (max-width: 400px) {
    .electron .page-container {
        min-height: calc(100% - 6.6em);
    }
}
