body {
    font-family: 'Roboto', sans-serif;
}

html, body {
    -webkit-tap-highlight-color: transparent;
}

html, body, #root, .container-fluid {
    height: 100%;
    min-height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
}

#root {
    display: block;
}

.c-pointer {
    cursor: pointer;
}

.spinner > div {
    margin-right: 3px;
}

.post-logo {
    height: 4.5em;
    background-color: #fc0;
}

.content-background {
    background-color: #f2f2f2;
}


    .content-background .dx-scrollable-content {
        height: 100% !important;
    }

    .content-background .dx-scrollview-content {
        height: 100% !important;
        display: block;
    }

.callback-cover {
    position: static;
    top: 0;
    left: 0;
    display: block;
    z-index: 10000;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
}

.no-data-found {
    width: 100%;
    font-size: 6em;
    color: #fcfcfc;
    text-align: center;
}

.img {
    display: inline-block;
    background-repeat: no-repeat, no-repeat;
    background-position: top left;
    background-size: cover, cover;
    background-attachment: fixed;
    width: 100%;
    height: 100%;
}

    .img.img0 {
        background-image: url('https://repo.eoscop.com/img/backgrounds/0.jpg');
    }

    .img.img1 {
        background-image: url('https://repo.eoscop.com/img/backgrounds/1.jpg');
    }

    .img.img2 {
        background-image: url('https://repo.eoscop.com/img/backgrounds/2.jpg');
    }

    .img.img3 {
        background-image: url('https://repo.eoscop.com/img/backgrounds/3.jpg');
    }

    .img.img4 {
        background-image: url('https://repo.eoscop.com/img/backgrounds/4.jpg');
    }

    .img.img5 {
        background-image: url('https://repo.eoscop.com/img/backgrounds/5.jpg');
    }

    .img.img6 {
        background-image: url('https://repo.eoscop.com/img/backgrounds/6.jpg');
    }

.page-container {
    background-color: #f2f2f2;
    min-height: calc(100% - 7.5em);
}

.page-title {
    padding: 0.2em 0 0.25em 0;
    margin-bottom: 0.5em;
    color: #3F2F1A;
    width: 110%;
    -webkit-box-shadow: rgba(88,65,37,0.2) 0 0.2em 0.125em -0.125em;
    -moz-box-shadow: rgba(88,65,37,0.2) 0 0.2em 0.125em -0.125em;
    box-shadow: rgba(88,65,37,0.2) 0 0.2em 0.125em -0.125em;
}

.page-title-native {
    position: relative;
    top: -0.8em;
    left: -1rem;
    width: calc(100% + 2rem);
    padding-left: 0.5em;
    margin-bottom: 0;
    line-height: 2.5em;
    height: 2.5em;
    vertical-align: middle;
    background-color: rgba(255, 255, 255, 0.9);
    -webkit-box-shadow: rgba(88,65,37,0.2) 0 0.2em 0.125em -0.125em;
    -moz-box-shadow: rgba(88,65,37,0.2) 0 0.2em 0.125em -0.125em;
    box-shadow: rgba(88,65,37,0.4) 0 0.2em 0.125em -0.125em;
}

    .page-title-native img {
        position: relative;
        float: right;
        top: 0.5em;
        right: 0.5em;
        width: 1.5em;
        height: 1.5em;
    }

.page-sub-title {
    display: block;
    font-size: 1.2em;
    margin-bottom: 0.5em;
    font-style: italic;
}

@media (max-width: 991px) {
    html, body {
        font-size: 1em !important;
    }

    .page-container {
        font-size: 0.9em;
        min-height: calc(100% - 4em);
    }

    .page-title {
        text-align: left;
        font-size: 1.3em;
    }

    .page-title-native {
        font-size: 1.3rem;
    }
}

@media (max-width: 800px) {
    .page-container {
        font-size: 0.8em;
    }

    .page-title-native {
        font-size: 1.2rem;        
    }
}

@media (max-width: 575px) {
    .page-container {
        min-height: calc(100% - 4.65em);
    }

    .page-title-native {
        font-size: 1.1rem;        
    }
}

@media (max-width: 400px) {
    .page-container {
        font-size: 0.7em;
    }

    .page-title-native {
        font-size: 1rem;        
    }
}
