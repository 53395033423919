footer {
    font-size: 0.7em;
    padding: 0.5em;
    font-weight: bold;
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr='#99000000', endColorstr='#99000000');
    cursor: default;
    color: white;
    height: 2.75em;
}

    footer.not-auth {
        background-color: #666;
        background-color: rgba(0,0,0,1);
        vertical-align: middle;
        display: block;
        line-height: 1.6em;
        padding-right: 1em;
        padding-left: 1em;
    }

        footer.not-auth span {
            color: #fff;
            text-decoration: none;
        }

    footer label {
        margin: 0;
    }

    footer a, footer .page-culture-item {
        cursor: pointer;
        color: #FFF;
    }

        footer a:hover, footer .page-culture-item:hover {
            text-decoration: none;
            color: #FC0;
        }

    footer.not-auth .fa-language {
        position: relative;
        bottom: 0.05em;
    }

    footer .privacy-policy {
        font-weight: bold;
        color: white;
    }

    footer.not-auth .privacy-policy {
        color: #fff;
    }

@media (min-height: 505px) {
    footer.not-auth {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        overflow: visible;
        z-index: 999;
        clear: both;
        vertical-align: middle;
    }
}

@media (max-width: 575px) {
    footer {
        height: 3.75em;
    }
}

footer.auth {
    background-color: #fff;
    color: #343a40;
    -webkit-box-shadow: rgba(88,65,37,0.2) 0 0.2em 0.125em -0.125em;
    -moz-box-shadow: rgba(88,65,37,0.2) 0 0.2em 0.125em -0.125em;
    box-shadow: rgba(88,65,37,0.4) 0 -0.2em 0.125em -0.125em;
}

    footer.auth a, footer.auth label {
        color: #343a40;
    }
