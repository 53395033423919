.menu-desktop {
    height: calc(100% - 3.45em);
}

.menu-global {
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: 1.1em;
}

    .menu-global .main {
        padding: 0.75em 0 0.75em 0;
        cursor: pointer;
        border-bottom: 1px solid #d8d8d8;
        -webkit-transition: all .2s ease-out;
        -moz-transition: all .2s ease-out;
        -o-transition: all .2s ease-out;
        transition: all .2s ease-out;
    }

    .menu-global .main {
        display: block;
        width: 100%;
    }

        .menu-global .main a {
            display: block;
            width: 100%;
        }

        .menu-global .main:hover {
            cursor: default;
        }

        .menu-global .main svg {
            position: relative;
            width: 1.5em;
            color: #3F2F1A;
            margin-right: 0.25em;
        }

        .menu-global .main a {
            color: #3F2F1A;
        }

            .menu-global .main a:hover {
                text-decoration: none;
            }

    .menu-global .item {
        width: 100%;
        text-decoration: none;
        cursor: pointer;
        color: #3F2F1A;
        height: 2em;
        line-height: 2em;
        text-align: left;
        vertical-align: middle;
        padding: 0 0 0 1.75em;
    }

        .menu-global .item:last-of-type {
            margin-bottom: 0.5em;
        }

        .menu-global .item span {
            display: inline-block;
            width: 100%;
            -webkit-transition: all .2s ease-out;
            -moz-transition: all .2s ease-out;
            -o-transition: all .2s ease-out;
            transition: all .2s ease-out
        }

            .menu-global .item span:hover {
                background-color: #f2f2f2;
                padding-left: 0.5em;
            }

        .menu-global .item a {
            display: block;
            width: 100%;
            color: #3F2F1A;
        }

            .menu-global .item a:hover {
                text-decoration: none;
                background-color: #f2f2f2;
            }

.menu-padding {
    padding: 1em !important;
}

.menu-border {
    -webkit-box-shadow: rgba(88,65,37,0.2) 0.15em 0.15em 0.2em -0.125em;
    -moz-box-shadow: rgba(88,65,37,0.2) 0.15em 0.15em 0.2em -0.125em;
    box-shadow: rgba(88,65,37,0.2) 0 0.15em 0.2em -0.125em;
}

.menu-border-inset {
    -webkit-box-shadow: inset rgba(88,65,37,0.2) -0.2em 0 0.125em -0.125em;
    -moz-box-shadow: inset rgba(88,65,37,0.2) -0.2em 0 0.125em -0.125em;
    box-shadow: inset rgba(88,65,37,0.2) -0.2em 0 0.125em -0.125em;
    z-index: 100;
}

.menu-toggler {
    cursor: pointer;
}

    .menu-toggler.open {
        border: none !important;
    }

        .menu-toggler.open svg {
            color: #3F2F1A;
            font-size: 2em;
            font-weight: bold;
        }

.menu-bar {
    position: fixed;
    top: 0em;
    left: -105%;
    z-index: 1000;
    width: 320px;
    max-width: 320px;
    height: calc(100% - 0em);
    background-color: #fff;
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    transition: all .3s ease-out;
}

    .menu-bar.toggled {
        left: 0;
    }

    .menu-bar .dx-item {
        color: #3F2F1A;
    }

    .menu-bar .dx-tab.dx-tab-selected {
    }

        .menu-bar .dx-tab.dx-tab-selected:before {
            background-color: #fff;
        }

    .menu-bar .menu-bar-tabs {
        -webkit-box-shadow: rgba(88,65,37,0.2) 0.15em 0.15em 0.2em -0.125em;
        -moz-box-shadow: rgba(88,65,37,0.2) 0.15em 0.15em 0.2em -0.125em;
        box-shadow: rgba(88,65,37,0.5) -0.18em 0 0.2em 0.05em;
    }

    .menu-bar .menu-item-container {
        padding-bottom: 10em;
        margin-left: 0.75em;
        margin-right: 0.75em;
    }

        .menu-bar .menu-item-container .main {
            padding-left: 0.5em;
        }

        .menu-bar .menu-item-container .item {
            padding-left: 2.25em;
        }

@media (max-width: 991px) {
    .menu-global {
        font-size: 1em;
    }

    .menu-bar {
        max-width: 100%;
        width: 100%;
    }
}
