.fade-enter {
    opacity: 0;
}

    .fade-enter.fade-enter-active {
        opacity: 1;
        transition: opacity 500ms ease-out;
    }

.fade-exit {
    opacity: 1;
}

    .fade-exit.fade-exit-active {
        opacity: 0.01;
        transition: opacity 500ms ease-out;
    }

.shadow {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.24);
}

.card-hilight:hover {
    -webkit-box-shadow: rgba(88,65,37,0.2) 0 0 0.125em;
    -moz-box-shadow: rgba(88,65,37,0.2) 0 0 0.125em;
    box-shadow: rgba(88,65,37,0.2) 0 0 0.125em;
}

.pre-line {
    white-space: pre-line;
}

.pre-wrap {
    white-space: pre-wrap;
}

.loadingPanelSpinner {
    color: #fc0;
    font-size: 4em;
    font-weight: bold;
}

.form-label svg.fa-asterisk {
    position: relative;
    bottom: 0.2em;
    font-size: 0.65em;
    color: #999;
}

.eos-danger {
    border-color: #d43f3a;
}

.toolbar {
    height: 36px;
    padding: 0;
    margin-bottom: 1.2em;
}

    .toolbar .dx-toolbar-items-container {
        height: 36px !important;
        background-color: #fff;
        -webkit-box-shadow: rgba(88,65,37,0.2) 0.07em 0.1em 0.125em -0.07em;
        -moz-box-shadow: rgba(88,65,37,0.2) 0.07em 0.1em 0.125em -0.07em;
        box-shadow: rgba(88,65,37,0.2) 0.07em 0.1em 0.125em -0.07em;
    }

    .toolbar:hover {
        -webkit-box-shadow: rgba(88,65,37,0.2) 0.125em 0.2em 0.25em -0.125em;
        -moz-box-shadow: rgba(88,65,37,0.2) 0.125em 0.2em 0.25em -0.125em;
        box-shadow: rgba(88,65,37,0.2) 0.125em 0.2em 0.25em -0.125em;
    }

    .toolbar .dx-button {
        border-color: #fff;
    }

    .toolbar .dx-button-default {
        border-color: #59432A;
    }

#new-content {
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    background-color: #3F2F1A;
    color: #fff;
    padding: 0.5em;
    -webkit-box-shadow: inset rgba(0,0,0,0.4) 0 -0.2em 0.2em 0.1em;
    -moz-box-shadow: inset rgba(0,0,0,0.4) 0 -0.2em 0.2em 0.1em;
    box-shadow: inset rgba(0,0,0,0.4) 0 -0.2em 0.2em 0.1em;
}

    #new-content .dx-button {
        margin-left: 0.5em;
    }

.overflow-y {
    overflow-y: scroll;
}

.card-title-toolbar .dx-toolbar-items-container {
    height: 46px;
}

.disable-selection {
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}

.v-center {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.far-highlight {
    border: 0.05em solid #343a40;
    color: #343a40;
    font-size: 2em;
    padding: 0.25em;
    border-radius: 0.2em;
}

.far-highlight-label {
    position: relative;
    left: 0.25em;
    bottom: 0.4em;
    color: #343a40;
}

    .far-highlight-label a {
        color: #343a40;
        text-decoration: none;
    }

.no-border {
    border: 0 none #fff;
}

.rtl-truncate {
    direction: rtl;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}