.info-bar {
    position: fixed;
    display: block;
    float: right;
    top: 0;
    right: -110%;
    z-index: 1200;
    width: 320px;
    max-width: 320px;
    height: 100%;
    background-color: rgb(255, 255, 255);
    background-color: rgba(255, 255, 255, 1);
    -webkit-box-shadow: rgba(88,65,37,0.2) -0.3em 0 0.2em -0.125em;
    -moz-box-shadow: rgba(88,65,37,0.2) -0.3em 0 0.2em -0.125em;
    box-shadow: rgba(88,65,37,0.2) -0.3em 0 0.2em -0.125em;
}

    .info-bar.toggled {
        right: 0;
    }

@media (max-width: 768px) {
    .info-bar {
        max-width: 100%;
        width: 100%;
    }
}
