.react-top-loading-bar {
    position:fixed;
    top:0;
    z-index: 10000;
}

.loading-bar-spinner {
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 900 !important;
}

.loading-bar-spinner-overlay {
    background: rgba(255, 255, 255, 0.5);
}

.loading-bar {
    position: fixed;
    top: 0;
    left: 0;
    height: 0.3em;
    background-color: #ffcc00;
    opacity: 1;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    z-index: 999999;
}