.navbar {
    z-index: 800 !important;
    background-color: #f2f2f2;
}

    .navbar .col {
        padding: 0 !important;
    }

    .navbar .navbar-collapse {
        height: 100%;
        min-height: 100%;
    }

    .navbar li {
        border-right: 1px solid #f2f2f2;
        border-left: 1px solid #f2f2f2;
        height: 3.5em !important;
        line-height: 3.5em !important;
        padding: 0;
    }

        .navbar li a {
            display: inline;
            color: #888;
            color: rgba(0,0,0,.5) !important;
        }

@media (max-width: 991px) {
    .navbar {
        background-color: white;
    }

        .navbar .navbar-brand {
            font-size: 1em;
        }
}
