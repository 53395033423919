.dx-context-menu .dx-submenu {
    border: none;
}

.shipper-filter-item {
    padding: 0.5em;
}

.top-bar {
    background-color: #3F2F1A;
    height: 3.5em;
}

    .top-bar .dx-context-menu-container-border {
        border: none;
    }

    .top-bar .dx-menu-item.dx-state-hover {
        background-color: #fc0;
        color: #fff;
    }

    .top-bar .dx-menu-horizontal {
        width: 100%;
        height: 100%;
        background-color: #f2f2f2;
        vertical-align: top;
    }

        .top-bar .dx-menu-horizontal::after {
            display: block;
        }

        .top-bar .dx-menu-horizontal ul {
            width: 100%;
            height: 100%;
            display: block;
            padding: 0;
        }

            .top-bar .dx-menu-horizontal ul li {
                display: inline-block;
                vertical-align: top;
                height: 100%;
                width: 100%;
                line-height: 100%;
                padding: 0;
            }

                .top-bar .dx-menu-horizontal ul li .dx-menu-item-content {
                    display: inline-block;
                    height: 100%;
                    line-height: 100%;
                    vertical-align: middle;
                    padding: 0;
                }

                .top-bar .dx-menu-horizontal ul li .dx-menu-item-content {
                    display: inline-block;
                    margin-bottom: 0;
                }

                    .top-bar .dx-menu-horizontal ul li .dx-menu-item-content .dx-menu-item-popout:before {
                        margin-top: -7px
                    }

                    .top-bar .dx-menu-horizontal ul li .dx-menu-item-content div {
                        position: relative;
                        top: 0.65em;
                        left: 0.5em;
                    }

                        .top-bar .dx-menu-horizontal ul li .dx-menu-item-content div .shipper-filter-title {
                            font-size: 0.8em;
                            font-weight: bold;
                        }

                        .top-bar .dx-menu-horizontal ul li .dx-menu-item-content div .shipper-filter-value {
                            position: relative;
                            top: 0.1em;
                            color: #413320;
                            font-size: 1.5em;
                        }

                            .top-bar .dx-menu-horizontal ul li .dx-menu-item-content div .shipper-filter-value a {
                                color: #413320;
                            }

                        .top-bar .dx-menu-horizontal ul li .dx-menu-item-content div svg {
                            float: right;
                            position: relative;
                            top: 0.6em;
                            right: 0.85em;
                            font-size: 1.25em;
                        }

    .top-bar .shipper-filter-item a {
        color: #000;
    }

    .top-bar .view-mode span {
        background-color: #3f2f1a !important;
        padding-top: 1.1em !important;
        padding-bottom: 0.7em !important;
        padding-left: 0 !important;
    }

        .top-bar .view-mode span svg {
            color: #fff;
            font-size: 1.1em;
            position: relative;
            bottom: 0.4em;
        }

        .top-bar .view-mode span .dx-switch {
            margin-right: 0.5em;
            margin-left: 0.5em;
        }

            .top-bar .view-mode span .dx-switch .dx-switch-on-value .dx-switch-handle:before {
                background-color: #fafafa !important;
            }

    .top-bar menu-bars-icon {
        -webkit-transition: all 0.5s linear;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }

    .top-bar .menu-bar-button {
        width: 1em;
        height: 1em;
        -webkit-transition: all 0.5s linear;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }

    .top-bar .lang {
        display: flex;
        align-items: center;
        padding-left: 2em;
    }

        .top-bar .lang .lang-item {
            cursor: pointer;
            color: #BCB1A3;
            font-weight: bold;
        }

            .top-bar .lang .lang-item:hover {
                text-decoration: none;
            }

            .top-bar .lang .lang-item.active {
                color: #fff;
            }

    .top-bar .right-menu {
        height: 100%;
        padding: 0;
        margin-right: 10px;
        margin-bottom: 0;
        list-style-type: none;
        list-style-image: none;
        list-style-position: inside;
    }

        .top-bar .right-menu li {
            display: inline-block;
            vertical-align: top;
            height: 100%;
            line-height: 100%;
            padding: 0;
        }

            .top-bar .right-menu li .dx-textbox {
                position: relative;
                border: none;
                line-height: 100%;
                height: 100%;
                background-color: white;
            }

            .top-bar .right-menu li a, .top-bar .right-menu li span {
                display: inline-block;
                background-color: rgb(128,111,96);
                background-color: rgba(128,111,96, 0.7);
                color: #fff;
                padding: 1.225em 1em 1.225em 1em;
                cursor: pointer;
                -webkit-transition: all 0.3s linear;
                -moz-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                transition: all 0.3s ease;
            }

            .top-bar .right-menu li span {
                padding-left: 1em !important;
            }

                .top-bar .right-menu li a:hover, .top-bar .right-menu li span:hover {
                    text-decoration: none;
                    color: #fff;
                    background-color: rgb(128,111,96);
                    background-color: rgba(128,111,96, 0.9);
                }

                .top-bar .right-menu li a.signout {
                    background-color: #fc0;
                    color: #3F2F1A;
                }

                    .top-bar .right-menu li a.signout:hover {
                        color: #fff;
                    }

@media (max-width: 991px) {

    .top-bar .dx-menu-horizontal ul li .dx-menu-item-content div {
        top: 0.5em;
    }

        .top-bar .dx-menu-horizontal ul li .dx-menu-item-content div .shipper-filter-value {
            position: relative;
            top: 0;
            color: #413320;
            font-size: 1.25em;
        }

        .top-bar .dx-menu-horizontal ul li .dx-menu-item-content div svg {
            top: 0.5em;
        }
}

@media (max-width: 400px) {
    .top-bar .view-mode {
        display: none !important;
    }
}
