/* React loading bar */
.Loading__bar___21yOt {
    height: 0.25em !important;
}

.Loading__peg___3Y_28 {
    height: 0.25em !important;
}
/* BOOTSTRAP */
.card {
    border-radius: 0;
}

.card-title {
    font-size: 1.5em;
    border-bottom: 2px solid#f2f2f2;
    padding-bottom: 0.25em;
}

/* DEVEXTREME */

.dx-scrollable-scroll-content {
    /*background-color: #fc0*/
}

.dx-button-content div {
    padding-top: 0.35em
}

.dx-toolbar-menu-section .dx-toolbar-hidden-button .dx-button-content {
    padding: 16px 16px;
}

.dx-accordion .dx-accordion-item-opened .dx-accordion-item-title {
    padding-top: 21px;
}

.dx-item.dx-tab.dx-tab-selected {
    background-color: #fff;
    color: #333;
}

.dx-switch {
    margin-right: 3em;
}

    .dx-switch.dx-switch-on-value .dx-switch-wrapper .dx-switch-container .dx-switch-inner .dx-switch-on {
        position: relative;
        color: #333;
        left: 3.3em;
        bottom: 0.05em;
        font-weight: bold;
        font-size: 0.8em;
    }

    .dx-switch.dx-switch-on-value .dx-switch-wrapper .dx-switch-container .dx-switch-inner .dx-switch-off {
        display: none;
    }

    .dx-switch .dx-switch-wrapper .dx-switch-container .dx-switch-inner .dx-switch-off {
        bottom: 0.05em;
        font-weight: bold;
        font-size: 0.8em;
        color: #333;
        margin-left: 1.75em;
    }

.dx-switch-off {
    display: inline;
}

.dx-texteditor.dx-editor-standard .dx-texteditor-input {
    padding: 0 0 6px;
}

.list-group-item .dx-toolbar-items-container {
    height: 3em;
}

.dx-switch-v-middle {
    position: relative;
    top: 0.725em;
}

.dx-switch-text .dx-switch-wrapper {
    overflow: visible
}

.dx-switch-text.dx-switch.dx-switch-on-value .dx-switch-wrapper .dx-switch-container .dx-switch-inner .dx-switch-on {
    left: 2.75em;
}

.dx-dropdowneditor-overlay .dx-overlay-content {
    min-width: 18em;
}

/*Grid view*/

.dx-datagrid-headers .dx-toolbar .dx-toolbar-items-container {
    height: 36px;
}

.dx-data-row .dx-toolbar {
    background-color: transparent;
}

.dx-data-row .dx-toolbar-after {
    top: -1.25em;
}

.dx-data-row .dx-toolbar .dx-toolbar-items-container {
    height: 0;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td:hover:not(.dx-command-select):not(.dx-command-expand):not(.dx-editor-cell):not(.dx-command-edit):not(.dx-datagrid-group-space) {
    background-color: #fff;
}

.alt-row {
    background-color: #FAFAFA;
}

.search-title-barcode {
    padding-top: 0.5em;
}

.dx-datagrid-rowsview .dx-row > .dx-master-detail-cell {
    padding-top: 1em;
    padding-bottom: 1em;
}

.dx-datagrid-rowsview .dx-master-detail-row:not(.dx-datagrid-edit-form) > .dx-datagrid-group-space, .dx-datagrid-rowsview .dx-master-detail-row:not(.dx-datagrid-edit-form) .dx-master-detail-cell {
    background-color: #fefefe;
}

.dx-datagrid-rowsview .dx-selection.dx-row > td, .dx-datagrid-rowsview .dx-selection.dx-row:hover > td {
    background-color: transparent;
}

@media (max-width: 991px) {
    .card-title {
        font-size: 1.2em;
    }
}

/*Vertical timeline*/

    .vertical-timeline .vertical-timeline-element-content .vertical-timeline-element-date {
        padding-bottom: 0;
        font-weight: 500;
    }

    .vertical-timeline .vertical-timeline-element-icon {
        -webkit-box-shadow: rgba(88,65,37,0.4) 0 0 0.725em;
        -moz-box-shadow: rgba(88,65,37,0.4) 0 0 0.725em;
        box-shadow: rgba(88,65,37,0.4) 0 0 0.725em;        
    }

        .vertical-timeline .vertical-timeline-element-icon svg {
            font-size: 1em;
            position: relative;
            margin-left: -0.65em
        }

    .vertical-timeline .vertical-timeline-element-content {
        -webkit-box-shadow: rgba(88,65,37,0.2) 0 0 0.325em;
        -moz-box-shadow: rgba(88,65,37,0.2) 0 0 0.325em;
        box-shadow: rgba(88,65,37,0.2) 0 0 0.325em;
        padding: 1.25rem !important;
        text-transform: capitalize;
        /*border-radius: 0;*/
    }

    .vertical-timeline .vertical-timeline-element-content-arrow {
        /*border-right: none;*/
    }

    .vertical-timeline .vertical-timeline-element-date {
        font-size: 1.1em;
    }

    .vertical-timeline-element {
        margin: 2em 0 !important;
    }